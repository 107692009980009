import { useEffect, useState } from "react"
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap"
import { useLocation, useParams } from "react-router"
import API from "../../../common/API"
import { getBreakpointCurrent } from "../../../common/BreakPoints"

import { CharityI } from "../../../common/interface/CharityI"
import { toast } from "react-toastify"
import Settings from "../../../common/Settings"
import { ensureHttpsUrl } from "../../../common/ensureHttpsUrl"

import { parsedUser } from "../../../common/GetCurrentUser"
import ActionCard from "./ActionCard"
import ReportIssue from "../../Common/ReportIssue/ReportIssue"
import ManageSocialMedia from "./ManageSocialMedia"
import { dateFormater } from "../../../common/date-formater"
import CharityPhotos from "./CharityPhotos"
import AffiliatedCompanies from "./AffiliatedCompanies"
import OtherFoundations from "./OtherFoundations"
import ManageVideos from "./ManageVideos"
import CharityVideos from "./CharityVideos"

import { useTranslation } from "react-i18next"
import CharityAddEdit from "../CharityAddEdit/CharityAddEdit"
import CommentSection from "./CommentSection"
import ManageGoals from "./ManageGoals"
import Donations from "./Donations"

interface RouteParams {
  id: string
}

const CharityDetails = () => {
  const [changeTab, setChangeTab] = useState(0)
  const { id } = useParams<RouteParams>()
  const [showReport, setShowReport] = useState(false)

  const { t } = useTranslation()

  const user = parsedUser()
  const [showManageSocialMedia, setShowManageSocialMedia] = useState(false)
  const [showManageVideos, setShowManageVideos] = useState(false)
  const [breakpoint, setBreakpoint] = useState(getBreakpointCurrent())
  const [charity, setCharity] = useState<CharityI | undefined | any>()
  const [showAddEdit, setShowAddEdit] = useState(false)
  const location = useLocation()
  const [showManageGoals, setShowManageGoals] = useState(false)
  const toggleChangeTab = (position: number) => setChangeTab(position)

  const bindDataRemote = async () => {
    try {
      let requestAPI = await API.getAction("charity/details?id=" + id)
      if (requestAPI.status === 200) {
        setCharity(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    }
  }

  const toggleReport = () => {
    setShowReport(!showReport)
  }
  const toggleSocialMedia = () => {
    setShowManageSocialMedia(!showManageSocialMedia)
    if (showManageSocialMedia) {
      bindDataRemote()
    }
  }
  const toggleVideos = () => {
    setShowManageVideos(!showManageVideos)
    if (showManageVideos) {
      bindDataRemote()
    }
  }
  const toggleAddEdit = () => {
    setShowAddEdit(!showAddEdit)
  }
  const toggleGoals = () => {
    setShowManageGoals(!showManageGoals)

    if (showManageGoals) {
      bindDataRemote()
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpointCurrent())
    }

    window.addEventListener("resize", handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <>
      {showManageGoals && <ManageGoals toggle={toggleGoals} record={charity} />}
      {showAddEdit && (
        <CharityAddEdit toggle={toggleAddEdit} record={charity} />
      )}
      {showManageSocialMedia && (
        <ManageSocialMedia toggle={toggleSocialMedia} charityId={id} />
      )}
      {showManageVideos && (
        <ManageVideos toggle={toggleVideos} charityId={id} />
      )}
      {showReport && <ReportIssue toggle={toggleReport} />}
      <Container fluid className="py-5 bg-light mx-0">
        <Container>
          <Row className="my-4 ">
            <Col xs={12} md={8}>
              <Card className="bg-white mb-4">
                <Card.Body>
                  {/* HEADER */}
                  <Row>
                    <Col className="col-auto ">
                      <div
                        style={{ height: "14rem", width: "14rem" }}
                        className="clickableCard"
                      >
                        <img
                          src={`${Settings.BasePath}files/${charity?.logo}`}
                          alt={charity?.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "0.25rem",
                          }}
                          loading="lazy"
                        />
                      </div>
                    </Col>
                    <Col>
                      <h1 className="text-primary mb-0 poppins-medium d-inline">
                        {charity?.name}
                      </h1>

                      <Dropdown className="d-inline ms-2 float-end">
                        <Dropdown.Toggle className="btn btn-clean clickable">
                          <i className="fa fa-ellipsis-v"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => toggleGoals()}>
                            {t("Manage Goal")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            // href="#/action-1"
                            onClick={() => {
                              toggleSocialMedia()
                            }}
                          >
                            {t("Manage Social Media")}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            {t("Manage Photos")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => toggleVideos()}>
                            {t("Manage Videos")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              toggleReport()
                            }}
                          >
                            {t("Report this foundation")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        className="btn btn-clean ms-2 float-end"
                        onClick={() => toggleAddEdit()}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                      <br />
                      <small className="text-muted poppins-medium">
                        {dateFormater(charity?.created)}
                      </small>

                      <div className=" d-flex align-items-end">
                        <div>
                          {charity?.socialNetwork &&
                            charity?.socialNetwork.length > 0 &&
                            charity?.socialNetwork.map(
                              (socialNetwork, index) => {
                                return (
                                  <a
                                    className="clickable me-2"
                                    key={index}
                                    title={socialNetwork.path}
                                    href={socialNetwork.path}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i
                                      className={`${socialNetwork.socialNetworkIcon} `}
                                    ></i>
                                  </a>
                                )
                              }
                            )}
                          <br />
                          <span className="text-secondary opacity-8 poppins-medium">
                            {t("Website")}:{" "}
                          </span>
                          <a
                            href={ensureHttpsUrl(charity?.website)}
                            className="text-primary"
                            style={{ textDecoration: "underline" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {charity?.website}
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="p-2">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item mb-0" role="presentation">
                      <button
                        className={`nav-link ${
                          changeTab === 0
                            ? "bg-primary text-light active"
                            : null
                        }`}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => toggleChangeTab(0)}
                      >
                        {t("About")}
                      </button>
                    </li>
                    <li className="nav-item  mb-0" role="presentation">
                      <button
                        className={`nav-link ${
                          changeTab === 1
                            ? "bg-primary text-light active"
                            : null
                        }`}
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => toggleChangeTab(1)}
                      >
                        {t("Words of Support")}
                      </button>
                    </li>
                  </ul>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <ActionCard
                charityId={id}
                moneyCurrent={17500}
                moneyGoal={charity?.pointsGoal ?? 0}
                quantityDonations={90}
                reload={bindDataRemote}
              />
            </Col>

            <Col xs={12} md={8}>
              <Card className="mb-4">
                {/* ABOUT */}
                {changeTab === 0 ? (
                  <>
                    <Card.Header className="poppins-bold">
                      {t("About Foundation")}
                    </Card.Header>
                    <Card.Body>
                      <div>
                        <p>{charity?.description}</p>
                      </div>
                    </Card.Body>
                  </>
                ) : changeTab === 1 ? (
                  <Card.Body>
                    <CommentSection />
                  </Card.Body>
                ) : null}
              </Card>

              {/* VIDEOS */}
              <CharityVideos charityId={charity?.id} />
              {/* IMAGES */}
              <Card className="mb-4">
                <Card.Header className="poppins-bold">
                  {t("Photos")}
                </Card.Header>
                <Card.Body>
                  <CharityPhotos charityId={charity?.id} />
                </Card.Body>
              </Card>
              {/* Affiliated companies */}
              <AffiliatedCompanies charityId={charity?.id} />

              <OtherFoundations charityId={charity?.id} />
            </Col>
            <Col xs={12} md={4}>
              {/* DONORS */}
              <Donations charityId={charity?.id} />
            </Col>

            {/* <Col xs={12}>
              <OtherList
                endpointName="company"
                btnRouteName="foundations"
                idEntity={id}
                name={t("Affiliated Companies")}
              />
            </Col> */}
            {/* <Col xs={12}>
              <OtherList
                endpointName="charity"
                btnRouteName="foundations"
                idEntity={id}
                name={t("Other foundations")}
              />
            </Col> */}
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default CharityDetails
