/* import Profile3 from "../assets/img/team/profile-picture-3.jpg" */

import { isEmpty } from "lodash"
import { FormatMoneyI } from "./interface/HelpersI"
import { number } from "yup"

let Profile3 = ""

export const PayrollTypes = { hourly: 4, weekly: 1, biWeekly: 2, monthly: 3 }
export const TransactionCalculationType = {
  percentage: 1,
  factor: 2,
  amount: 0,
}
export const TransactionType = {
  baseAmount: 1,
  benefits: 2,
  discounts: 3,
}

const colors = ["3f67fa"] //["3f67fa", "fbcb6d", "0e1d2c", "bdbcd9"]
const getRandom = <T>(array: T[], cut?: boolean): T | undefined => {
  if (array.length === 0) return undefined

  const i = Math.floor(Math.random() * array.length)
  if (cut) {
    return array.splice(i, 1)[0]
  }
  return array[i]
}

export const userAvatar = (userName: string, size: number) => {
  if (userName === null) {
    return Profile3 || ""
  }
  let spli = userName.split(" ")
  let initials = ""
  spli.forEach(element => {
    if (initials.length < 2) {
      initials += element.substring(0, 1).toUpperCase()
    }
  })

  let colorToUse = getRandom(colors)
  size = size ? size : 75
  let path =
    "https://via.placeholder.com/" +
    size +
    "/" +
    colorToUse +
    "/ffffff/?text=" +
    initials
  return path
}

export const onlyHTMLTags = text => {
  let hold = document.createElement("div")
  hold.innerHTML = text.trim()
  return hold.innerText.length === 0
}

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split(".")[1]))
  } catch (e) {
    return null
  }
}

export const groupBy = (collection, property) => {
  console.log(collection)
  console.log(property)
  const groupedData = groupBy
  const result = groupedData
  console.table(result)
  return result

  // console.log(property)
  // let i = 0, values = [], result = [];
  // for (i; i < collection.length; i++) {
  //   if (values.indexOf(collection[i][property]) === -1) {
  //     values.push(collection[i][property]);
  //     result.push(collection.filter(function (v) { return v[property] === collection[i][property] }));
  //   }
  // }
  // console.table(result)
  // return result;
}

export const payrollStatusParsed = item => {
  switch (item) {
    case 1:
      return "En progreso"
    case 2:
      return "Aprobada"
    case 3:
      return "Cancelad"
    default:
      return "-"
  }
}

export const benefitsAndDiscountParsed = item => {
  switch (item) {
    case 0:
      return "Monto"
    case 1:
      return "Porcentaje"
    case 2:
      return "Fracción"
    default:
      return "-"
  }
}

export const sortArray = (records, field) => {
  if (!Array.isArray(records)) {
    console.log(records)
    return records === undefined ? [] : records
  }

  return records.sort((a, b) => {
    console.log(a, b, field)
    if (!a[field] || !b[field]) return 0
    let nameA = typeof a[field] === "number" ? a[field] : a[field].toUpperCase() // ignore upper and lowercase
    let nameB = typeof b[field] === "number" ? b[field] : b[field].toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })
}

//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = function ({ n, c, decimal, t }: FormatMoneyI) {
  let s, i, j
  ;(c = isNaN((c = Math.abs(c))) ? 2 : c),
    (decimal = decimal === undefined ? "." : decimal),
    (t = t === undefined ? "," : t),
    (s = n < 0 ? "-" : ""),
    (i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))))),
    (j = (j = i.length) > 3 ? j % 3 : 0)
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? decimal +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  )
}

export const NumberFormat = function (item) {
  return new Intl.NumberFormat().format(isNaN(item * 1) ? 0 : item * 1)
}

export const makeId = length => {
  let result = ""
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const getAbbreviation = text => {
  console.log(text)
  if (text === "") {
    return ""
  }
  return text
    .match(/\b(\w)/g)
    .join("")
    .toUpperCase()
}

export const productTypes = {
  product: "product",
  asset: "asset",
}

export const AbstractName = (name: string): string => {
  let namesByParts: string[] = !isEmpty(name)
    ? name.split(" ").map(word => word[0])
    : []

  if (namesByParts.length > 3) {
    name = namesByParts[0] + namesByParts[1]
  } else if (namesByParts.length > 1) {
    name = namesByParts[0] + namesByParts[1]
  } else if (namesByParts.length > 0) {
    name = namesByParts[0]
  } else {
    name = "N/A"
  }

  return name
}

export const CalculatePercent = (from = 0, until = 0): number =>
  (from / until) * 100

//Extract youtube id from youtube url
//example url https://www.youtube.com/watch?v=BndmSOkqnaY&ab_channel=Acumen
//return BndmSOkqnaY=
export const getYoutubeId = (url: string) => {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  let match = url.match(regExp)
  return match && match[7].length == 11 ? match[7] : false
}

export enum DonatorType {
  GUEST = 1,
  USER = 2,
}
