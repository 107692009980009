import { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import Timer from "../../Common/Timer"
import { DonatorType } from "../../../common/helpers"

const Donate = props => {
  const [donateAsGuest, setDonateAsGuest] = useState<boolean>()
  const [selectedCompany, setSelectedCompany] = useState<any>(null)
  const [records, setRecords] = useState<any>([])
  const [model, setModel] = useState<any>({})
  const [amountToDonate, setAmountToDonate] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const requestCompanies = async () => {
    let requestAPI = await API.getAction(
      "charity/RelatedCompanies?id=" + props.id
    )

    if (requestAPI.data.status === "ok") {
      console.log(requestAPI.data.response)
      setRecords(requestAPI.data.response)
    }
  }

  const makeDonation = async () => {
    try {
      let query = {
        charityId: props.id,
        companyId: selectedCompany.id,
        amount: amountToDonate,
        donationType: donateAsGuest ? DonatorType.GUEST : DonatorType.USER,
        donatorName: model.donatorName ?? null,
        donatorLastName: model.donatorLastName ?? null,
        donatorPhone: model.donatorPhone ?? null,
        donationPlatformId: null, // TODO: assign
        verificationCode: null, // TODO: assign
      }

      // Validations
      if (query.donationType === DonatorType.GUEST) {
        if (
          !query.donatorName ||
          !query.donatorLastName ||
          !query.donatorPhone
        ) {
          alert(t("Please fill all the fields"))
          return
        }
      }

      // Send request
      setIsLoading(true)
      const request = await API.postAction("donation/AddEdit", query)

      if (request.data.status === "ok") {
        console.log(request.data)
        props.toggle()
      } else {
        alert(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.id) requestCompanies()
  }, [props.id])
  return (
    <Modal show={true} onHide={props.toggle} centered size="lg">
      <Modal.Header>
        <Modal.Title>{t("Donate")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h4>1. {t("Select related company")}</h4>
              <Row>
                {records.length > 0 &&
                  records.map((item: any, index: number) => {
                    console.log(item)
                    let today = new Date()
                    let nextRaffleDate = item.nextRaffle
                      ? new Date(item.nextRaffle)
                      : new Date()
                    let calculateDateDiff =
                      nextRaffleDate.getTime() - today.getTime()
                    console.log(
                      "date diff",
                      today,
                      nextRaffleDate,
                      calculateDateDiff
                    )
                    return (
                      <Col key={index} className=" py-2" xs={12} md={6}>
                        <Card
                          className={
                            "clickable " +
                            (selectedCompany?.id === item.id
                              ? "border border-primary"
                              : "")
                          }
                          onClick={() => setSelectedCompany(item)}
                        >
                          <Card.Body>
                            <Row className=" rounded ">
                              <Col className="col-auto">
                                <img
                                  src={`${Settings.BasePath}files/${item.logo}`}
                                  alt={item.name}
                                  style={{
                                    width: "32px",
                                    maxHeight: "32px",
                                    objectFit: "cover",
                                    borderRadius: "0.25rem",
                                  }}
                                  loading="lazy"
                                />
                              </Col>
                              <Col>
                                <span>{item.name}</span>
                                <br />
                                <small className="text-primary me-2">
                                  {t("IBBs")} {item.accumulatedIbbs}
                                </small>
                                {calculateDateDiff > 0 && (
                                  <>
                                    <br />
                                    <small className="text-primary me-2">
                                      {t("Next Raffle")}&nbsp;
                                      <Timer
                                        deadline={
                                          item.nextRaffle ??
                                          new Date().toISOString()
                                        }
                                      />
                                    </small>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  })}
              </Row>
            </Col>
            <Col xs={12} md={12} className="mt-2">
              <h4>2. {t("How much you would like to donate?")}</h4>
              <div className="input-group">
                <span className="input-group-text">{t("IBBs")}</span>
                <input
                  type="number"
                  className="form-control"
                  value={amountToDonate ?? 0}
                  onChange={(e: any) => {
                    let val: number = e.target.value * 1
                    setAmountToDonate(val)
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="mt-2 py-4">
              <h4>3. {t("How you would like to donate?")}</h4>
              <button
                onClick={e => {
                  setDonateAsGuest(true)
                }}
                className="btn bg-white text-dark me-2"
                disabled={!selectedCompany || !amountToDonate}
              >
                {t("Donate as guest")}
              </button>
              <span className="mx-2">{t("or")}</span>
              <button
                onClick={() => {
                  setDonateAsGuest(false)
                  // TODO: go to process
                  makeDonation()
                }}
                className="btn btn-primary"
                disabled={!selectedCompany || !amountToDonate || isLoading}
              >
                {t("Donate")}
              </button>
            </Col>
          </Row>
          {donateAsGuest && (
            <Row>
              <Col xs={12}>
                <h4 className="mt-4">{t("Donator info.")}</h4>
                <hr />
              </Col>
              <Col xs={12} md={6}>
                <label>{t("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.donatorName}
                  onChange={e => {
                    setModel({
                      ...model,
                      donatorName: e.target.value,
                    })
                  }}
                ></input>
              </Col>
              <Col xs={12} md={6}>
                <label>{t("Last Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.donatorLastName}
                  onChange={e => {
                    setModel({
                      ...model,
                      donatorLastName: e.target.value,
                    })
                  }}
                ></input>
              </Col>
              <Col xs={12} md={6}>
                <label>{t("Phone")}</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.donatorPhone}
                  onChange={e => {
                    setModel({
                      ...model,
                      donatorPhone: e.target.value,
                    })
                  }}
                ></input>
              </Col>
              <Col xs={12} className="text-end">
                <hr />
                <button
                  disabled={isLoading}
                  onClick={e => {
                    // TODO: go to process
                    makeDonation()
                  }}
                  className="btn btn-primary"
                >
                  {t("Make Donation")}
                </button>
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>

      {/* </Modal.Dialog> */}
    </Modal>
  )
}

export default Donate
