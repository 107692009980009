import { t } from "i18next"
import { FC, useState } from "react"
import { ProgressBar } from "react-bootstrap"

import Donate from "./Donate"
import { useTranslation } from "react-i18next"

interface ActionCardProps {
  moneyCurrent?: number
  moneyGoal?: number
  quantityDonations?: number
  charityId?: string
  reload?: () => void
}

const ActionCard: FC<ActionCardProps> = ({
  charityId,
  moneyCurrent = 0,
  moneyGoal = 0,
  quantityDonations = 0,
}) => {
  const [showDonate, setShowDonate] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      {showDonate && <Donate id={charityId} toggle={setShowDonate} />}
      <div className="bg-white rounded shadow">
        <div className="row p-3">
          <div>
            <h1 className="text-primary mb-0 poppins-medium ">
              {`${t("IBBs")} ${moneyCurrent}`}
            </h1>
            <small className="text-muted poppins-medium">
              {`${t("raised of IBBs")} ${moneyGoal}`}
            </small>

            <ProgressBar
              /* style={{ height: "0.4rem" }} */
              className="my-2"
              now={moneyCurrent}
              max={moneyGoal}
            />
            <small className="text-muted poppins-medium">
              {quantityDonations} {t("donations")}
            </small>
          </div>

          <div className="mt-1 mb-3 d-grid">
            <button
              className="btn btn-lg btn bg-violetblue effect-bg-violetblue text-white py-3"
              onClick={() => setShowDonate(!showDonate)}
            >
              {t("Donate Now")}
            </button>
            <button
              className="btn btn-secondary  btn-lg my-2  py-3"
              onClick={async () =>
                await navigator.share({
                  title: t("Donate Now"),
                  text: t("Donate Now"),
                  url: window.location.href,
                })
              }
            >
              {/* <i className="fa fa-share me-2"></i> */}
              {t("Share")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActionCard
