import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import { userAvatar } from "../../../common/helpers"

const Donations = props => {
  const [records, setRecords] = useState<any>([])
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      const request = await API.getAction(
        "donation/list?charityId=" + props.charityId
      )

      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [props.charityId])
  return (
    <Card className="mb-4">
      <Card.Header className="poppins-bold">{t("Donors")}</Card.Header>
      <Card.Body>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {records &&
          records.length > 0 &&
          records.map((item, index) => {
            console.log(item)
            return (
              <Row className="mb-2" key={index}>
                <Col xs={"auto"}>
                  <img
                    src={userAvatar(
                      item.donatorName ?? item.createdBy?.firstName,
                      60
                    )}
                    className="user-avatar circle"
                    alt="User"
                  />

                  {/* <img
                    src={"https://i.pravatar.cc/150?img=67"}
                    style={{ width: "60px" }}
                    className="img-fluid rounded-circle"
                    alt="logo"
                  /> */}
                </Col>
                <Col>
                  <b>
                    {item.donatorName
                      ? `${item.donatorName} ${item.donatorLastName}`
                      : item.createdBy
                      ? `${item.createdBy.firstName} ${item.createdBy.lastName}`
                      : "Unknown"}
                  </b>
                  <br />
                  <span className="text-secondary">
                    {t("IBBs")} {item.amount}
                  </span>
                </Col>
              </Row>
            )
          })}
      </Card.Body>
      <Card.Footer className="text-center">
        <button className="btn btn-clean">{t("View all donors")}</button>
      </Card.Footer>
    </Card>
  )
}

export default Donations
